import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88')
];

export const server_loads = [0,5,6,9,10,4,3];

export const dictionary = {
		"/(app)": [14,[2]],
		"/(app)/+[usernameOrId=username_or_id]": [~23,[2]],
		"/(app)/+[usernameOrId=username_or_id]/og.png/preview": [~24,[2]],
		"/(app)/@[org=org_code]": [~25,[2,5]],
		"/(app)/@[org=org_code]/(members)/billing": [~26,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/contests": [27,[2,5,6]],
		"/(app)/@[org=org_code]/join": [35,[2,5]],
		"/(app)/@[org=org_code]/join/check-email": [36,[2,5]],
		"/(app)/@[org=org_code]/join/with-email": [~37,[2,5]],
		"/(app)/@[org=org_code]/(members)/leave": [28,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/members": [~29,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/notifications": [30,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/sessions": [31,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/settings": [32,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/teams": [33,[2,5,6]],
		"/(app)/@[org=org_code]/[code=session_code]": [~34,[2,5]],
		"/_email/manage-topics": [~87,[13]],
		"/_email/unsubscribe": [~88,[13]],
		"/(app)/admin": [38,[2]],
		"/(app)/admin/booth": [39,[2]],
		"/(app)/admin/colors": [40,[2]],
		"/(app)/admin/components": [41,[2]],
		"/(app)/admin/exercises": [42,[2]],
		"/(app)/admin/new-movement": [43,[2]],
		"/(app)/admin/posts": [44,[2,7]],
		"/(app)/admin/posts/[postId=uid]": [~45,[2,7]],
		"/(app)/admin/robot": [46,[2]],
		"/(app)/admin/stats": [~47,[2]],
		"/(app)/admin/stripe": [~48,[2]],
		"/(app)/blog": [~49,[2,8]],
		"/(app)/blog/[slug]": [50,[2,8,9]],
		"/(app)/blog/[slug]/og.png/preview": [51,[2,8,9]],
		"/(app)/business": [52,[2]],
		"/(app)/contact": [53,[2]],
		"/(app)/contests/[contestId=integer]": [54,[2]],
		"/(app)/help": [~55,[2,10]],
		"/(app)/help/[...slugs]": [~56,[2,10]],
		"/(app)/internal/exercise": [57,[2]],
		"/(app)/legal/legal-notice": [58,[2,11]],
		"/(app)/legal/privacy-policy": [59,[2,11]],
		"/(app)/legal/terms-of-service": [60,[2,11]],
		"/(app)/(auth)/(signed-out)/login": [16,[2,4]],
		"/(app)/(auth)/logout": [19,[2]],
		"/(app)/ms-teams-tab": [61,[2]],
		"/(app)/pomodoro": [~62,[2]],
		"/(app)/pricing": [~63,[2]],
		"/(app)/(auth)/(signed-out)/register": [17,[2,4]],
		"/(app)/(auth)/register/finish": [20,[2]],
		"/(app)/(auth)/(signed-out)/reset-password": [18,[2,4]],
		"/(app)/(auth)/reset-password/set": [21,[2]],
		"/(app)/session/create": [66,[2]],
		"/(app)/session/embeddable": [67],
		"/(app)/session/[sessionUid=session_uid]": [~64,[2]],
		"/(app)/session/[sessionUid=session_uid]/join": [65,[2]],
		"/(app)/settings": [68,[2,12]],
		"/(app)/settings/account": [69,[2,12]],
		"/(app)/settings/billing": [~70,[2,12]],
		"/(app)/settings/contests": [71,[2,12]],
		"/(app)/settings/contests/new": [74,[2,12]],
		"/(app)/settings/contests/[contestId=integer]": [72,[2,12]],
		"/(app)/settings/contests/[contestId=integer]/edit": [73,[2,12]],
		"/(app)/settings/friends": [75,[2,12]],
		"/(app)/settings/friends/requests": [~76,[2,12]],
		"/(app)/settings/notifications": [77,[2,12]],
		"/(app)/settings/notifications/link-ms": [78,[2,12]],
		"/(app)/settings/notifications/link-slack": [79,[2,12]],
		"/(app)/settings/organizations": [80,[2,12]],
		"/(app)/settings/organizations/add-slack-workspace": [81,[2,12]],
		"/(app)/settings/organizations/add-slack-workspace/success": [82,[2,12]],
		"/(app)/settings/organizations/link-slack": [~83,[2,12]],
		"/(app)/settings/organizations/link-team": [~84,[2,12]],
		"/(app)/settings/organizations/new": [85,[2,12]],
		"/(app)/settings/profile": [86,[2,12]],
		"/(app)/(auth)/verify-email": [22,[2]],
		"/(app)/(auth)/(signed-in)/welcome": [15,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';